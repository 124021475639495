<template>
    <div class="row mx-0">
        <div class="col my-0 px-0">
            <p>
                {{ msg.mensaje }}
            </p>
            <div class="row py-3 mx-0 mb-3 px-0 pb-3 border-bottom">
                <div class="col-auto px-0 d-flex flex-column a-center">
                    <img :src="msg.creador.imagen" width="55" height="55" class="position-absolute obj-cover rounded-circle border-grey" style="top:-13px;left:-9px;" />
                    <div class="ticket-name-responsable py-1 pr-2 pl-5 tres-puntos text-center" style="max-width:170px;">
                        {{ msg.creador.nombre }}
                    </div>
                    <i class="pl-5 f-15 text-light-gris">
                        <i class="icon-star-circled f-18 text-yellow" /> Responsable
                    </i>
                </div>
                <div v-loading="loadingLike" class="col-auto d-middle">
                    <i :class="`${msg.my_like ? 'icon-thumb-up' : 'icon-thumb-up-outline'} text-general f-18 cr-pointer`" @click="toogleLike(msg)" /> 
                    {{ msg.likes_count }}
                </div>
                <!-- <div class="col-auto ml-auto">
                    <div class=" cr-pointer py-2 text-general f-600" @click="responder(msg.id)">
                        Responder 
                    </div>
                </div> -->
                <div class="col-auto d-middle px-0">
                    <div class="triangle-up" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Foros from '~/services/foros/foros'

export default {
    props:{
        msg:{
            type: Object,
            default: () => {
                return {
                    titulo: '',
                    texto: '',
                    mensajes_count: 0,
                    visualizaciones_count: 0,
                    my_like: 0,
                    likes_count: 0,
                    creador:{
                        nombre: '',
                        imagen: null
                    }
                }
            }
        }
    },
    data(){
        return {
            loadingLike:false
        }
    },
    methods: {
        async toogleLike(msg){
            try {
                const payload = {
                    id_mensaje: msg.id,
                    created_by: msg.creador.id
                }
                this.loadingLike = true
                const {data:{data}} = await Foros.likeMensaje(payload)
                this.loadingLike = false
                console.log('msg', msg);
                msg.likes_count = data.elimino? msg.likes_count -1 : msg.likes_count +1
                msg.my_like = !data.elimino
            } catch (e){
                this.loadingLike = true
                this.error_catch(e)
            }
        
        }

    }
}
</script>

<style lang="scss" scoped>
.ticket-name-responsable{
    background-color: #FFA04157;    
    color: var(--color-grey);
    border-radius: 0px 20px 20px 0px;
}
</style>